<template>
    <form class="form center-educenter" @submit.prevent="summit" autocomplete="off">
        <hr class="header-title-dialog">
        <div class="form-row">
            <div class="col-md-12">
                <div class="form-group">
                    <div class="checkbox-inline">
                        <label class="checkbox checkbox-success text-dark-100 font-weight-bolder" style="font-size: 14px!important;">
                            <input type="checkbox" name="check" v-model="checkBox.all" class="mr-4" @change="handleAllChange()"/>
                            <span></span>Chọn tất cả
                        </label>
                    </div>
                    <span ></span>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <div class="checkbox-inline">
                        <label class="checkbox checkbox-success title-center">
                            <input type="checkbox" name="name" v-model="checkBox.name" class="mr-4" @change="checkRemoveAll"/>
                            <span></span>Tên trung tâm
                        </label>
                    </div>
                    <p class="text-dark-50 ml-8">{{ center.name }}</p>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <div class="checkbox-inline">
                        <label class="checkbox checkbox-success title-center">
                            <input type="checkbox" name="avatar" v-model="checkBox.avatar" class="mr-4" @change="checkRemoveAll"/>
                            <span></span>Ảnh trung tâm
                        </label>
                    </div>
                    <p class="ml-8" v-if="center.avatar"><img class="img-center" :src="center.avatar"></p>
                    <p v-else class="ml-8 font-italic">Chưa có</p>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <div class="checkbox-inline">
                        <label class="checkbox checkbox-success title-center">
                            <input type="checkbox" name="phone" v-model="checkBox.phone" class="mr-4" @change="checkRemoveAll"/>
                            <span></span>Số điện thoại
                        </label>
                    </div>
                    <p class="ml-8">{{ center.phone }}</p>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <div class="checkbox-inline">
                        <label class="checkbox checkbox-success title-center">
                            <input type="checkbox" name="avatar" v-model="checkBox.address" class="mr-4" @change="checkRemoveAll"/>
                            <span></span>Địa chỉ
                        </label>
                    </div>
                    <p class="ml-8">{{ center.address }}</p>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <div class="checkbox-inline">
                        <label class="checkbox checkbox-success title-center">
                            <input type="checkbox" name="avatar" v-model="checkBox.short_description" class="mr-4" @change="checkRemoveAll"/>
                            <span></span>Mô tả ngắn
                        </label>
                    </div>
                    <div class="card card-body">
                        <div class="ml-8" v-html="center.short_description"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <div class="checkbox-inline">
                        <label class="checkbox checkbox-success title-center">
                            <input type="checkbox" name="avatar" v-model="checkBox.long_description" class="mr-4" @change="checkRemoveAll"/>
                            <span></span>Mô tả chi tiết
                        </label>
                    </div>
                    <div class="card card-body">
                        <p class="ml-8" v-html="center.long_description"></p>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <div class="checkbox-inline">
                        <label class="checkbox checkbox-success title-center">
                            <input type="checkbox" name="avatar" v-model="checkBox.intro" class="mr-4" @change="checkRemoveAll"/>
                            <span></span>Giới thiệu
                        </label>
                    </div>
                    <div class="card card-body">
                        <p class="ml-8" v-html="center.information.intro"></p>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <div class="checkbox-inline">
                        <label class="checkbox checkbox-success title-center">
                            <input type="checkbox" name="avatar" v-model="checkBox.special" class="mr-4" @change="checkRemoveAll"/>
                            <span></span>Điểm nổi bật
                        </label>
                    </div>
                    <div class="card card-body">
                        <p class="ml-8" v-html="center.information.special"></p>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <div class="checkbox-inline">
                        <label class="checkbox checkbox-success title-center">
                            <input type="checkbox" name="avatar" v-model="checkBox.course" class="mr-4" @change="checkRemoveAll"/>
                            <span></span>Bảng giá khóa học
                        </label>
                    </div>
                    <div class="card card-body">
                        <p class="ml-8" v-html="center.information.course"></p>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <div class="checkbox-inline">
                        <label class="checkbox checkbox-success title-center">
                            <input type="checkbox" name="avatar" v-model="checkBox.schedule" class="mr-4" @change="checkRemoveAll"/>
                            <span></span>Lộ trình và cam kết
                        </label>
                    </div>
                    <div class="card card-body">
                        <p class="ml-8" v-html="center.information.schedule"></p>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <div class="checkbox-inline">
                        <label class="checkbox checkbox-success title-center">
                            <input type="checkbox" name="avatar" v-model="checkBox.teacher" class="mr-4" @change="checkRemoveAll"/>
                            <span></span>Đội ngũ giảng viên
                        </label>
                    </div>
                    <div class="card card-body">
                        <p class="ml-8" v-html="center.information.teacher"></p>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <div class="checkbox-inline">
                        <label class="checkbox checkbox-success title-center">
                            <input type="checkbox" name="avatar" v-model="checkBox.sale" class="mr-4" @change="checkRemoveAll"/>
                            <span></span>Chương trình khuyến mãi
                        </label>
                    </div>
                    <div class="card card-body">
                        <p class="ml-8" v-html="center.information.sale"></p>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <div class="checkbox-inline">
                        <label class="checkbox checkbox-success title-center">
                            <input type="checkbox" name="avatar" v-model="checkBox.feedback" class="mr-4" @change="checkRemoveAll"/>
                            <span></span>Đánh giá của học viên
                        </label>
                    </div>
                    <div class="card card-body">
                        <p class="ml-8" v-html="center.information.feedback"></p>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <div class="checkbox-inline">
                        <label class="checkbox checkbox-success title-center">
                            <input type="checkbox" name="avatar" v-model="checkBox.gallery" class="mr-4" @change="checkRemoveAll"/>
                            <span></span>Thư viện ảnh của trung tâm
                        </label>
                    </div>
                    <div class="card card-body" v-if="center.thumbnail.length">
                        <p><img v-for="img_gallery in center.thumbnail" class="img-center mr-2 mt-2" :src="img_gallery.url" :key="img_gallery"></p>
                    </div>
                    <div v-else>
                        <p class="ml-8 font-italic">Chưa có</p>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mb-3">
                <button :disabled="loading.save" @click="setAlarm = true" class="btn btn-success float-right mt-3 ml-3 dat-lich-tvv">
                    <i v-if="loading.save" class="el-icon-loading"></i>
                    Sử dụng
                </button>
            </div>
        </div>
    </form>
</template>

<script>
    import {mapGetters} from "vuex";
    import '@/assets/sass/custom/custom_event.scss';

    export default {
        props: {
            center: {
                default: null
            },
            data: Object,
        },
        data() {
            return {
                checkBox: {
                    all: false,
                    name: false,
                    avatar: false,
                    phone: false,
                    address: false,
                    short_description: false,
                    long_description: false,
                    intro: false,
                    special: false,
                    course: false,
                    schedule: false,
                    teacher: false,
                    sale: false,
                    feedback: false,
                    gallery: false
                },
                check_show: false,
                loading: {
                    save: false,
                    loadingSelect: false
                },
                item: {},
            }
        },
        methods: {
            close() {
                this.$emit('close')
            },
            summit() {
                this.$emit('completed', this.checkBox);
            },
            checkRemoveAll() {
                for (var key in this.checkBox) {
                    if (this.checkBox[key] != true) {
                        this.checkBox.all = false
                        return false
                    }
                }
            },
            handleAllChange() {
                if (this.checkBox.all) {
                    this.checkBox.name = true
                    this.checkBox.avatar = true
                    this.checkBox.phone = true
                    this.checkBox.address = true
                    this.checkBox.short_description = true
                    this.checkBox.long_description = true
                    this.checkBox.intro = true
                    this.checkBox.special = true
                    this.checkBox.course = true
                    this.checkBox.schedule = true
                    this.checkBox.teacher = true
                    this.checkBox.sale = true
                    this.checkBox.feedback = true
                    this.checkBox.gallery = true
                } else {
                    this.checkBox.name = false
                    this.checkBox.avatar = false
                    this.checkBox.phone = false
                    this.checkBox.address = false
                    this.checkBox.short_description = false
                    this.checkBox.long_description = false
                    this.checkBox.intro = false
                    this.checkBox.special = false
                    this.checkBox.course = false
                    this.checkBox.schedule = false
                    this.checkBox.teacher = false
                    this.checkBox.sale = false
                    this.checkBox.feedback = false
                    this.checkBox.gallery = false
                }
            }
        },
        mounted() {
        },
        computed: {
            ...mapGetters(['currentUser'])
        }
    }
</script>
